<script>
import Layout from "@/router/layouts/exchange";
import { VclList } from "vue-content-loading";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Crypto Deposits': 'Entrada de moedas',
      'Identifier': 'Identificador',
      'Currency': 'Moeda',
      'Created on': 'Criado em',
      'Confirmed on': 'Confirmada em',
      'Transaction': 'Transação',
      'Value': 'Valor',
      'State': 'Estado',
      'Copy': 'Copiar',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Crypto Deposits': 'Entrada de moedas',
      'Identifier': 'Identificador',
      'Currency': 'Moeda',
      'Created on': 'Criado em',
      'Confirmed on': 'Confirmada em',
      'Transaction': 'Transação',
      'Value': 'Valor',
      'State': 'Estado',
      'Copy': 'Copiar',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      filter: {
        currency: {
          value: 'all',
          options: {
            all: 'Todos',
            omc: 'OMC',
          }
        },
        status: {
          value: 'all',
          options: {
            all: 'Todos',
            pending: 'Pendentes',
            confirmed: 'Confirmado',
            canceled: 'Cancelado',
          }
        }
      },

      page: 1,
      pages: 0,

      table: {
        heade: [
          'Identifier', 'Currency', 'Created on', 'Confirmed on', 'Transaction', 'Value', 'State'
        ],
        body: [],
        loading: true,
        errored: false,
        empty: false,
        modal: false,
      },
    };
  },
  methods: {
    getDeposits() {
      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('exchange/crypto/deposit?page=' + this.page + '&currency=' + this.filter.currency.value + '&status=' + this.filter.status.value)
        .then((response) => {
          if (response.data.status == 'success') {
            this.pages = response.data.pages
            this.table.body = response.data.list

            this.table.empty = false
            this.table.loading = false
          } else {
            this.table.empty = true
            this.table.loading = false
          }
        })
        .catch(error => {
          this.table.errored = error
          this.table.empty = false
          this.table.loading = false
        })
    }
  },
  mounted() {
    this.getDeposits()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0">{{ t('Crypto Deposits') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body d-flex">
            <div class="mr-3">
              <b-input-group :prepend="t('Currency')">
                <b-form-select v-on:change="getDeposits()" v-model="filter.currency.value" :options="filter.currency.options"></b-form-select>
              </b-input-group>
            </div>
            <div class="mr-3">
              <b-input-group :prepend="t('Status')">
                <b-form-select v-on:change="getDeposits()" v-model="filter.status.value" :options="filter.status.options"></b-form-select>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap table-striped">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td>{{ td.code }}</td>
                    <td class="text-uppercase">{{ td.currency }}</td>
                    <td>{{ td.create_date }}</td>
                    <td>{{ td.confirmation_date }}</td>
                    <td class="d-flex">
                      <span class="align-self-center text-truncate text-primary pr-3">{{ td.hash }}</span>
                      <button class="btn btn-primary btn-sm" v-clipboard:copy="td.hash">{{ t('Copy') }}</button>
                    </td>
                    <td>{{ td.value }}</td>
                    <td class="text-uppercase">{{ td.state }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="table.modal" :title="t('Bitcoin')" centered>
    </b-modal>
  </Layout>
</template>

<style scoped>
.text-truncate {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
  text-overflow: ellipsis;
  width: calc(100vw * 0.2);
}
</style>